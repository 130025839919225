import React, { useMemo, useState } from "react";
import SelectModal from "../modals/SelectModal";
import DriverCard from "../components/DriverCard";
import { useDrivers } from "../hooks";
import { Driver } from "../types";

interface ISelect {
  editable: boolean;
  value: string;
  onChange: (e: any) => void;
}

const Select: React.FC<ISelect> = ({ editable, value, onChange }) => {
  const driversState = useDrivers();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const driver = useMemo(
    () => driversState.getDriverById(value) as Driver,
    [value]
  );

  const handleSelected = (e: any) => {
    if (e) {
      onChange(e);
    }
    setModalOpen(false);
  };
  const handleClick = () => {
    if (!editable) return;
    setModalOpen(true);
  };
  return (
    <>
      <DriverCard _id={driver?._id!} clicked={handleClick} />
      <SelectModal open={modalOpen} selected={handleSelected}></SelectModal>
    </>
  );
};

export default Select;
