import { network } from "./";
import { logger } from "../utils";
import { Driver } from "../types";

const BASE = "driver";

export const getDriver = async (raceId: string) => {
  try {
    const { data } = await network.get<Driver>(`${BASE}/${raceId}`);
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const getAllDrivers = async () => {
  try {
    const { data } = await network.get<Driver[]>(`${BASE}`);
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return [];
  }
};

export const postDriver = async (driver: Driver) =>
  await network.post<Driver>(BASE, driver);

export const updateDriver = async (driverId: string, driver: Partial<Driver>) =>
  await network.put(`${BASE}/${driverId}`, driver);
