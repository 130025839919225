import React, { useMemo } from "react";
import { useNavigateSearch, useQuery, useRaces } from "../hooks";
import { IRace } from "../types";
import { format } from "date-fns";
import { GenericPage } from "./GenericPage";
import RaceDetailsContainerCard from "../components/RaceDetailsContainerCard";
import { Box, Button, Card } from "@wix/design-system";
import { useSettings } from "../providers/SettingsProvider";
import { observer } from "mobx-react-lite";

const _RacePage: React.FC = () => {
  const { documentSizes } = useSettings();
  const navigate = useNavigateSearch();
  const { query } = useQuery();

  const state = useRaces();
  const race = useMemo<IRace>(
    () => state.getRaceById(query.get("raceId") as string) as IRace,
    [query, state.races]
  );

  const getDateParamaters = (date: Date) =>
    date && {
      day: format(new Date(date), "d"),
      month: format(new Date(date), "LLL"),
      time: format(new Date(date), "kk:mm"),
    };

  const raceDate = useMemo(() => getDateParamaters(race?.raceDate), [race]);
  const qualifyingDate = useMemo(
    () => getDateParamaters(race?.qualifyingDate),
    [race]
  );
  const sprintDate = useMemo(
    () => race?.sprintDate && getDateParamaters(race?.sprintDate),
    [race]
  );

  console.log("[Race](race)", race);

  const handleClick = (path: string) => {
    navigate(path, { raceId: race?._id });
  };

  const handleBack = () => {
    navigate("/races");
  };

  return (
    <GenericPage>
      <Card>
        <Card.Header title={race?.country} subtitle={race?.name} />
        <Card.Divider />
        <Box
          direction="vertical"
          gap={4}
          padding="20px"
          overflowY="auto"
          maxHeight={`${documentSizes.height - 210}px`}
        >
          <Box
            width="100%"
            position="relative"
            direction="vertical"
            padding="20px 20px 20px 0"
            gap="15px"
            borderRight="10px solid #e10600"
            borderTop="10px solid #e10600"
            borderBottom="10px solid #e10600"
            borderTopRightRadius="10px"
            borderBottomRightRadius="10px"
          >
            <Box gap={1} direction="vertical">
              {sprintDate && (
                <>
                  <RaceDetailsContainerCard
                    name="Sprint"
                    day={sprintDate.day}
                    month={sprintDate.month}
                    time={sprintDate.time}
                    onClick={() => handleClick("/sprint-bet")}
                  />
                  <RaceDetailsContainerCard
                    name="Sprint Result"
                    day={sprintDate.day}
                    month={sprintDate.month}
                    onClick={() => handleClick("/sprint-bet/result")}
                  />
                </>
              )}
              <RaceDetailsContainerCard
                name="Qualifying"
                day={qualifyingDate?.day}
                month={qualifyingDate?.month}
                time={qualifyingDate?.time}
                onClick={() => handleClick("/qualifying-bet")}
              />
              <RaceDetailsContainerCard
                name="Qualifying Result"
                day={qualifyingDate?.day}
                month={qualifyingDate?.month}
                onClick={() => handleClick("/qualifying-bet/result")}
              />
              <RaceDetailsContainerCard
                name="Race"
                day={raceDate?.day}
                month={raceDate?.month}
                time={raceDate?.time}
                onClick={() => handleClick("/race-bet")}
              />
              <RaceDetailsContainerCard
                name="Race Result"
                day={raceDate?.day}
                month={raceDate?.month}
                onClick={() => handleClick("/race-bet/result")}
              />
            </Box>
          </Box>
          <Button skin="destructive" priority="secondary" onClick={handleBack}>
            Back
          </Button>
        </Box>
      </Card>
    </GenericPage>
  );
};

export const RacePage = observer(_RacePage);
