import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DriverCard from "../components/DriverCard";
import Modal from "../generics/Modal";
import { useDrivers } from "../hooks";

interface Props {
  open: boolean;
  selected: (e: any) => void;
}

const SelectModal: React.FC<Props> = ({ open, selected }) => {
  const state = useDrivers();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const handleClose = (event: any) => {
    setIsOpen(false);
    selected(
      typeof event === "string" || typeof event === "number" ? event : ""
    );
  };
  const handleClick = (e: any) => {
    setIsOpen(false);
    selected(typeof e === "string" || typeof e === "number" ? e : "");
  };
  return (
    <Modal
      openModal={isOpen}
      closeModal={handleClose}
      hiddeCloseButton={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModal>
        <CardsList>
          {state.activeDrivers?.map((driver: any) => (
            <DriverCard
              key={driver._id}
              _id={driver._id}
              clicked={handleClick}
            />
          ))}
        </CardsList>
      </StyledModal>
    </Modal>
  );
};

export default SelectModal;

const StyledModal = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardsList = styled.div`
  /* background-color: #fff;
  width: 50%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 60%;
  /* height: 100%; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;

  &:focus {
    outline: "none";
  }
`;
