import {
  Cell,
  Layout,
  FormField,
  Input,
  Card,
  ToggleSwitch,
  Button,
  Loader,
  Modal,
  Dropdown,
} from "@wix/design-system";
import { useEffect, useMemo, useState } from "react";
import { Add } from "@wix/wix-ui-icons-common";
import { Driver } from "../types";
import { observer } from "mobx-react-lite";
import { DriversState } from "../state";
import { useTeams } from "../hooks";

interface CreateDriverModalProps {
  state: DriversState;
}

const _CreateDriverModal: React.FC<CreateDriverModalProps> = ({ state }) => {
  const teamsState = useTeams();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [newDriver, setNewDriver] = useState<any>({});

  useEffect(() => {
    setNewDriver(state.selectedDriver);
    setIsChecked(
      typeof state.selectedDriver?.isActive === "boolean"
        ? state.selectedDriver?.isActive
        : false
    );
  }, [state.selectedDriver]);

  const handleClose = () => {
    state.selectedDriver = undefined;
    state.openCreateDriverModal = false;
  };

  const updateDriver = (key: string, value: any) => {
    setNewDriver({ ...newDriver, [key]: value });
  };

  const submitDriver = () => {
    const driverToCreate: Driver = {
      _id: newDriver?._id,
      firstName: newDriver?.firstName,
      lastName: newDriver?.lastName,
      team: newDriver?.team,
      isActive: isChecked,
    };
    console.log(driverToCreate);
    state.submitDriver(driverToCreate);
  };

  const options = useMemo(
    () => teamsState.teams.map((team) => ({ id: team._id, value: team.name })),
    [teamsState.teams]
  );

  return (
    <Modal
      isOpen={state.openCreateDriverModal}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      shouldDisplayCloseButton
      screen="desktop"
    >
      <Card>
        <Card.Header
          title="Driver info"
          suffix={
            <Button
              size="small"
              prefixIcon={state.isLoading ? <Loader size="tiny" /> : <Add />}
              onClick={submitDriver}
            >
              {state.isLoading ? "" : "Create"}
            </Button>
          }
        />
        <Card.Divider />
        <Card.Content>
          <Layout>
            <Cell>
              <FormField label="First name" required>
                <Input
                  value={newDriver?.firstName}
                  onChange={(e) => updateDriver("firstName", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell>
              <FormField label="Last name" required>
                <Input
                  value={newDriver?.lastName}
                  onChange={(e) => updateDriver("lastName", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell>
              <FormField label="Team" required>
                <Dropdown
                  selectedId={newDriver?.team}
                  options={options}
                  onSelect={(option) => updateDriver("team", option.id)}
                />
              </FormField>
            </Cell>
            <Cell>
              <FormField labelPlacement="left" label="Is active ?">
                <ToggleSwitch
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              </FormField>
            </Cell>
          </Layout>
        </Card.Content>
      </Card>
    </Modal>
  );
};

export const CreateDriverModal = observer(_CreateDriverModal);
