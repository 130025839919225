import React, { createContext, useContext, useState } from "react";
import { DriversState } from "../state";

const DriversContext = createContext<DriversState | undefined>(undefined);

export const useDrivers = () => {
  const context = useContext(DriversContext);
  if (!context)
    throw new Error("useDrivers must be used within a DriversProvider");
  return context;
};

export const DriversProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state] = useState(() => new DriversState());

  return (
    <DriversContext.Provider value={state}>{children}</DriversContext.Provider>
  );
};
