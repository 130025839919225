import React, { createContext, useContext, useState } from "react";
import { TeamsState } from "../state";

const TeamsContext = createContext<TeamsState | undefined>(undefined);

export const useTeams = () => {
  const context = useContext(TeamsContext);
  if (!context) throw new Error("useTeams must be used within a TeamsProvider");
  return context;
};

export const TeamsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state] = useState(() => new TeamsState());

  return (
    <TeamsContext.Provider value={state}>{children}</TeamsContext.Provider>
  );
};
