import { makeAutoObservable } from "mobx";
import { Team } from "../types";
import { getAllTeams } from "../api";

export class TeamsState {
  private _teams: Team[];
  private _isLoading: boolean;

  private _openCreateTeamModal: boolean = false;

  constructor() {
    this._teams = [];
    this._isLoading = false;

    makeAutoObservable(this);

    this.init();
  }

  get teams() {
    return this._teams;
  }

  get isLoading() {
    return this._isLoading;
  }

  get openCreateTeamModal() {
    return this._openCreateTeamModal;
  }

  set openCreateTeamModal(open: boolean) {
    this._openCreateTeamModal = open;
  }

  getTeamById(id: string) {
    return this.teams.find((team) => team._id === id);
  }

  private async init() {
    this._isLoading = true;
    // fetch teams
    this.getAllTeams()
      .then((teams) => {
        this._teams = teams;
      })
      .finally(() => (this._isLoading = false));
  }

  private getAllTeams = async () => {
    return getAllTeams();
  };
}
