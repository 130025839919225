import React from "react";
import { DriversProvider, RacesProvider } from "../hooks";
import { UserProvider } from "../hooks/useUser";
import { TeamsProvider } from "../hooks/useTeams";

export const Providers: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <UserProvider>
      <TeamsProvider>
        <DriversProvider>
          <RacesProvider>{children}</RacesProvider>
        </DriversProvider>
      </TeamsProvider>
    </UserProvider>
  );
};
