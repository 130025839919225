import { observer } from "mobx-react-lite";
import { GenericPage } from "./GenericPage";
import { Box, Card, IconButton, Loader } from "@wix/design-system";
import { Driver } from "../types";
import { Add } from "@wix/wix-ui-icons-common";
import { useDrivers } from "../hooks";
import { useSettings } from "../providers";
import { CreateDriverModal } from "../modals/CreateDriverModal";
import DriverCard from "../components/DriverCard";

const _DriversPage = () => {
  const { documentSizes } = useSettings();
  const state = useDrivers();

  const handleSelectedDriver = (driver: Driver) => {
    state.selectedDriver = driver;
    state.openCreateDriverModal = true;
  };

  return (
    <GenericPage>
      <Card>
        <Card.Header
          title="Drivers"
          subtitle={`active: ${state.activeDriversCount}`}
          suffix={
            <Box gap="18px" verticalAlign="middle">
              <IconButton
                size="small"
                skin="dark"
                onClick={() => (state.openCreateDriverModal = true)}
              >
                <Add />
              </IconButton>
            </Box>
          }
        />
        <Card.Divider />
        <Card.Content>
          {state.isLoading ? (
            <Box align="center">
              <Loader />
            </Box>
          ) : (
            <Box
              overflowY="auto"
              direction="vertical"
              maxHeight={`${documentSizes.height - 200}px`}
            >
              {state.drivers.map((driver) => (
                <Box opacity={driver.isActive ? 1 : 0.5} width="100%">
                  <DriverCard
                    _id={driver._id!}
                    clicked={() => handleSelectedDriver(driver)}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Card.Content>
      </Card>
      <CreateDriverModal state={state} />
    </GenericPage>
  );
};

export const DriversPage = observer(_DriversPage);
