import { makeAutoObservable } from "mobx";
import { Driver } from "../types";
import { getAllDrivers, postDriver, updateDriver } from "../api";

export class DriversState {
  private _drivers: Driver[];
  private _isLoading: boolean;

  private _openCreateDriverModal: boolean = false;

  private _selectedDriver: Driver | undefined;

  constructor() {
    this._drivers = [];
    this._isLoading = false;

    makeAutoObservable(this);

    this.init();
  }

  get drivers() {
    return this._drivers;
  }

  get isLoading() {
    return this._isLoading;
  }

  get openCreateDriverModal() {
    return this._openCreateDriverModal;
  }

  set openCreateDriverModal(open: boolean) {
    this._openCreateDriverModal = open;
  }

  get selectedDriver() {
    return this._selectedDriver;
  }

  set selectedDriver(driver: Driver | undefined) {
    this._selectedDriver = driver;
  }

  get activeDrivers() {
    return this._drivers.filter((driver) => driver.isActive);
  }

  get activeDriversCount() {
    return this.activeDrivers.length;
  }

  getDriverById(id: string) {
    return this.drivers.find((driver) => driver._id === id);
  }

  private async init() {
    this._isLoading = true;
    // fetch drivers
    this.getAllDrivers()
      .then((drivers) => {
        this._drivers = drivers;
      })
      .finally(() => (this._isLoading = false));
  }

  submitDriver = (driver: Driver) => {
    const request = driver._id
      ? this.updateDriver(driver._id, driver)
      : this.createDriver(driver);

    return request
      .then((driver) => {
        this._openCreateDriverModal = false;
      })
      .finally(() => this.init());
  };

  private getAllDrivers = async () => {
    return getAllDrivers();
  };

  private createDriver = async (driver: Driver) => {
    return postDriver(driver);
  };

  private updateDriver = async (driverId: string, driver: Partial<Driver>) => {
    return updateDriver(driverId, driver);
  };
}
