import React, { useMemo } from "react";
import styled from "styled-components";
import { useDrivers, useTeams } from "../hooks";
import { Driver, Team } from "../types";
import { getColorTeam } from "../utils/team";

interface IDriverCardProps {
  _id: string;
  selected?: boolean;
  clicked?: (_id: string) => void;
}

const CardContainer = styled.div<{ selected: boolean; empty: boolean }>`
  height: 30px;
  width: 100%;
  opacity: ${(props) => (props.selected ? 0.3 : props.empty ? 0.7 : 1)};
  padding: 0.5em;
  border: 1px solid black;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div<{ color: string; empty: boolean }>`
  position: relative;
  padding-left: 0.625em;
  opacity: ${(props) => (props.empty ? 0 : 1)};
  ::before {
    content: "";
    background-color: ${(props) => props.color};
    bottom: 0.125em;
    display: block;
    left: 0;
    position: absolute;
    top: 0.125em;
    width: 0.25em;
  }
`;

const DriverNameContainer = styled.div`
  display: flex;
  align-items: center;
  & > :first-of-type {
    //font-family: F1;
    font-size: 0.75em;
    //line-height: 15px;
    letter-spacing: 0.5px;
    font-weight: 400;
    text-transform: capitalize;
    margin-right: 0.25em;
  }

  & > :last-of-type {
    //font-family: F1Bold;
    font-size: 0.75em;
    //line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
`;

const TeamName = styled.div`
  //font-family: F1;
  font-size: 0.6125em;
  letter-spacing: 0.5px;
  font-weight: 400;
`;

const DriverCard: React.FC<IDriverCardProps> = ({
  _id,
  selected = false,
  clicked,
}) => {
  const state = useDrivers();
  const teamsState = useTeams();

  const driver = useMemo(
    () => state.getDriverById(_id) as Driver | undefined,
    [_id]
  );

  const team = useMemo(
    () => teamsState.getTeamById(driver?.team as string) as Team,
    [driver]
  );

  const handleClick = (_id: string) => {
    !selected && clicked && clicked(_id);
  };
  return (
    <CardContainer
      selected={selected}
      onClick={() => handleClick(_id)}
      empty={!driver}
    >
      <InfoContainer color={getColorTeam(team?.name)} empty={!driver}>
        <DriverNameContainer>
          <div>{driver?.firstName || "driver"}</div>
          <div>{driver?.lastName || "name"}</div>
        </DriverNameContainer>
        <TeamName>{team?.name || "team"}</TeamName>
      </InfoContainer>
    </CardContainer>
  );
};

export default DriverCard;
